.dot-l {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 5px;
    /* filter: blur(4px); */
    background: #000;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
}

.dots-l {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}

.span-css-load {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    /* filter: blur(4px); */
    background: #7b61ff;
    border-radius: 50%;
}

@keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}