* {
    box-sizing: border-box;
  }
  
  .hide {
    display: none !important;
  }
  
  body {
    font-family: Helvetica Neue;
  }
  .intro {
    margin-top: 32px;
    text-align: center;
  }
  
  .badges {
       display: flex;
  /* 	 flex-direction: column; */
       justify-content: flex-start;
    flex-wrap: wrap;
       align-items: center;
       padding: 32px 0;
    text-align: center;
    width: 100%;
  }
  .badges .animated_badge_svg {
       display: block;
       position: relative;
  }
  .badges .animated_badge {
       position: relative;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       width: 156px;
       height: 156px;
       margin: 24px 64px;
       background-color: #fff;
       border-radius: 100px;
       box-shadow: 0px 2px 4px rgba(25, 35, 49, 0.02), 0px 24px 32px rgba(25, 35, 49, 0.08), 0px 4px 40px rgba(0, 0, 0, 0.12);
       animation: fade-in-top 1.5s ease forwards;
       will-change: transform, opacity;
       opacity: 0;
  }
  .badges .animated_badge::before {
       content: '';
       position: absolute;
       left: 19px;
       top: 19px;
       width: 118px;
       height: 118px;
       background-color: #f1f1eb;
       border-radius: 100px;
       opacity: 0;
       animation: fade-in 1.5s ease forwards;
       will-change: transform, opacity;
  }
  
  /* .badges .animated_badge_1 {
    animation-delay: 0s;
  }
  .badges .animated_badge_2 {
    animation-delay: 2s;
  }
  .badges .animated_badge_3 {
    animation-delay: 4s;
  }
  .badges .animated_badge_4 {
    animation-delay: 6s;
  }
  .badges .animated_badge_5 {
    animation-delay: 8s;
  } */
  
  .badges .animated_badge .badge_ribbon {
       opacity: 0;
       transform: translate(-5px, -10px) rotate(75deg) scale(0.5);
       transform-origin: center;
       animation: badge-ribbon-left-animation 1s 0.5s ease forwards;
       will-change: transform, opacity;
  }
  .badges .animated_badge .badge_ribbon.right {
       opacity: 0;
       transform: translate(5px, -10px) rotate(-75deg) scale(0.5);
       transform-origin: center;
       animation: badge-ribbon-right-animation 1s 0.5s ease forwards;
       will-change: transform, opacity;
  }
  .badges .animated_stars {
       position: absolute;
       left: 0;
       top: 0;
  }
  .badges .animated_stars .animated_star {
       position: absolute;
       opacity: 0;
       animation: star-animation 0.6s 1s ease forwards;
       will-change: transform, opacity;
  }
  .badges .animated_stars .animated_star:nth-of-type(1) {
       left: -6px;
       top: 26px;
       animation-delay: 1s;
  }
  .badges .animated_stars .animated_star:nth-of-type(2) {
       left: 32px;
       top: 14px;
       animation-delay: 1.05s;
  }
  .badges .animated_stars .animated_star:nth-of-type(3) {
       left: 75px;
       top: -12px;
       animation-delay: 1.1s;
  }
  .badges .animated_stars .animated_star:nth-of-type(4) {
       left: 121px;
       top: 9px;
       animation-delay: 1.15s;
  }
  .badges .animated_stars .animated_star:nth-of-type(5) {
       left: 152px;
       top: 35px;
       animation-delay: 1.2s;
  }
  .badges .animated_stars .animated_star.plus {
       width: 12px;
       height: 12px;
  }
  .badges .animated_stars .animated_star.plus::before {
       content: '';
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       margin: auto;
       width: 12px;
       height: 4px;
       background-color: #d5d4d1;
       border-radius: 10px;
  }
  .badges .animated_stars .animated_star.plus::after {
       content: '';
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       margin: auto;
       width: 4px;
       height: 12px;
       background-color: #d5d4d1;
       border-radius: 10px;
  }
  .badges .animated_stars .animated_star.plus.yellow::before, .badges .animated_stars .animated_star.plus.yellow::after {
       background-color: #fcd977;
  }
  .badges .animated_stars .animated_star.donut {
       position: relative;
       width: 10px;
       height: 10px;
       border: 3px solid #fcd977;
       background-color: transparent;
       border-radius: 10px;
  }
  
  @keyframes star-animation {
       0% {
           opacity: 1;
           transform: scale(0.2);
      }
       50% {
           opacity: 1;
           transform: scale(1.5);
      }
       100% {
           opacity: 1;
           transform: scale(1);
      }
  }
  @keyframes badge-circle-animation {
       0% {
           opacity: 0;
           transform: translateY(-6px) scale(0.5);
      }
       100% {
           opacity: 1;
           transform: translateY(0) scale(1);
      }
  }
  @keyframes badge-ribbon-left-animation {
       0% {
           opacity: 0;
           transform: translate(-5px, -10px) rotate(75deg) scale(0.5);
      }
       100% {
           opacity: 1;
           transform: translate(0) rotate(0deg) scale(1);
      }
  }
  @keyframes badge-ribbon-right-animation {
       0% {
           opacity: 0;
           transform: translate(5px, -10px) rotate(-75deg) scale(0.5);
      }
       100% {
           opacity: 1;
           transform: translate(0) rotate(0deg) scale(1);
      }
  }
  
  @keyframes fade-in-top {
      0% {
         opacity: 0;
         transform: translateY(20px);
      }
  
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
  }
  
  @keyframes fade-in {
      0% {
          opacity: 0;
      }
  
      100% {
          opacity: 1;
      }
  }