.addproblem-page-css {
    padding: 0;
    margin: 0;
    color: #1a1f36;
    box-sizing: border-box;
    word-wrap: break-word;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
  }
  .heading-addproblem {
    letter-spacing: -1px;
    color: #5469d4;
    text-decoration: unset;
    font-weight: bolder;
  }
  .link-css-addproblem{
    letter-spacing: -1px;
    color: #5469d4;
    text-decoration: unset;
  }
  .login-root {
      background: #fff;
      display: flex;
      width: 100%;
      min-height: 100vh;
      overflow: hidden;
  }
  .loginbackground {
      min-height: 692px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 0;
      overflow: hidden;
  }
  .flex-flex {
      display: flex;
  }
  .align-center {
    align-items: center; 
  }
  .center-center {
    align-items: center;
    justify-content: center;
  }
  .box-root {
      box-sizing: border-box;
  }
  .flex-direction--column {
      -ms-flex-direction: column;
      flex-direction: column;
  }
  .loginbackground-gridContainer {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: [start] 1fr [left-gutter] (86.6px)[16] [left-gutter] 1fr [end];
      grid-template-columns: [start] 1fr [left-gutter] repeat(16,86.6px) [left-gutter] 1fr [end];
      -ms-grid-rows: [top] 1fr [top-gutter] (64px)[8] [bottom-gutter] 1fr [bottom];
      grid-template-rows: [top] 1fr [top-gutter] repeat(8,64px) [bottom-gutter] 1fr [bottom];
      justify-content: center;
      margin: 0 -2%;
      transform: rotate(-12deg) skew(-12deg);
  }
  .box-divider--light-all-2 {
      box-shadow: inset 0 0 0 2px #e3e8ee;
  }
  .box-background--blue {
      background-color: #5469d4;
  }
  .box-background--white {
    background-color: #ffffff; 
  }
  .box-background--blue800 {
      background-color: #212d63;
  }
  .box-background--gray100 {
      background-color: #e3e8ee;
  }
  .box-background--cyan200 {
      background-color: #7fd3ed;
  }
  .padding-top--64 {
    padding-top: 64px;
  }
  .padding-top--24 {
    padding-top: 24px;
  }
  .padding-top--48 {
    padding-top: 48px;
  }
  .padding-bottom--24 {
    padding-bottom: 24px;
  }
  .padding-horizontal--48 {
    padding: 48px;
  }
  .padding-bottom--15 {
    padding-bottom: 15px;
  }
  
  
  .flex-justifyContent--center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .formbg {
      margin: 0px auto;
      width: 100%;
      max-width: 80%;
      background: white;
      border-radius: 4px;
      box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  }

.span-addproblem{
  background-color: rgb(84, 105, 212);
  color: #fff;
  font-weight: 700;
}
  
  input[type="submit"] {
      background-color: rgb(84, 105, 212);
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                  rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, 
                  rgb(84, 105, 212) 0px 0px 0px 1px, 
                  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                  rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                  rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
  }
  
  .animationRightLeft {
    animation: animationRightLeft 2s ease-in-out infinite;
  }
  .animationLeftRight {
    animation: animationLeftRight 2s ease-in-out infinite;
  }
  .tans3s {
    animation: animationLeftRight 3s ease-in-out infinite;
  }
  .tans4s {
    animation: animationLeftRight 4s ease-in-out infinite;
  }
  
  @keyframes animationLeftRight {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0px);
    }
  } 
  
  @keyframes animationRightLeft {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0px);
    }
  } 


  .wrapper {
    display: inline-flex;
    background: #fff;
    width: 240px;
    height: 37px;
    align-items: center;
    justify-content: space-evenly;
}

.wrapper .option-b {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid rgb(84, 105, 212);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-style: none;
    transition: all 0.3s ease;
}

.wrapper .option-s {
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid rgb(84, 105, 212);
    border-left-style: none;
    transition: all 0.3s ease;
}

.input-display-none {
    display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
    border-color: rgb(84, 105, 212);
    background: rgb(84, 105, 212);
}

/* 
.wrapper .option span {
    font-size: 20px;
    color: #808080;
} */

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
    color: #fff;
}